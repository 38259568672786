import * as React from "react"
import { Link } from "gatsby"

export default function Header(props) {
  return (
  <div class="container">
      <div class="section-links">
        <section>
          {/* <div class="link_heading">
            <h3>Resources</h3>
            <p>Learn about the core concepts and the main points of Tinyman and DeFi products of Algorand ecosystem. Swapping, pooling, fees, Tinyman brand and more...</p>
           </div> */}
          <div>
              <div class="helper-link">
                <a href="https://aist-tech.gitbook.io/aist-tech-docs/o-kompanii/dokumentaciya" class="outlink" target="_blank" rel="noreferrer"><h5>О протоколе</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://aist-tech.gitbook.io/aist-tech-docs/o-kompanii/dokumentaciya" class="outlink" target="_blank" rel="noreferrer"><h5>Сервисы и продукты </h5></a>
              </div>
              <div class="helper-link">
                <a href="https://medium.com/@aisttechco" class="outlink" target="_blank" rel="noreferrer"><h5>Блог </h5></a>
              </div>
              <div class="helper-link">
                <a href="https://t.me/aisttech" class="outlink" target="_blank" rel="noreferrer"><h5>Telegram</h5></a>
              </div>
              <div class="helper-link">
                <a href="" class="outlink" target="_blank" rel="noreferrer"><h5>Vk</h5></a>
              </div>
            </div>
        </section>
        <section>
          {/* <div class="link_heading">
            <h3>Community</h3>
            <p>We have a great community of experienced users of Tinyman and Algorand ecosystem. Join us and ask your questions to the community.</p>
          </div> */}
          <div>
              <div class="helper-link">
                <a href="" class="outlink" target="_blank" rel="noreferrer"><h5>Справка</h5></a>
              </div>
              <div class="helper-link">
                <a href="https://aist-tech.gitbook.io/aist-tech-docs/o-kompanii/dokumentaciya" class="outlink" target="_blank" rel="noreferrer"><h5>FAQ</h5></a>
              </div>
              <div class="helper-link">
                <Link to="/soon" class="outlink" target="_blank" rel="noreferrer"><h5>Исследователям ML и Ai</h5></Link>
              </div>
              <div class="helper-link">
                <Link to="/soon" class="outlink" target="_blank" rel="noreferrer"><h5>Бета версия</h5></Link>
              </div>
              <div class="helper-link">
                <Link to="/soon" class="outlink" target="_blank" rel="noreferrer"><h5>GitHub</h5></Link>
              </div>
          </div>
        </section>
      </div>
      <footer>
        <div class="end">
          <p class="copyright">@ 2023 “Аист Тех”</p>
          <Link to="/">T&C</Link>
        </div>
      </footer>
    </div>
  )
}